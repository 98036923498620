.autocomplete-container {
  position: relative;
  padding-top: 15px;
}

.floating-label {
  position: absolute;
  top: 0;
  left: 10px;
  color: #757575;
  font-size: 14px;
  transition: 0.2s ease all;
  pointer-events: none;
}

.e-input-group .e-input, .e-autocomplete {
  padding-top: 20px; /* To push the placeholder down */
}

.e-input-group.e-control-wrapper, .e-autocomplete.e-control-wrapper {
  border: 1px solid #c5c5c5;
}

.autocomplete-container .e-autocomplete:focus + .floating-label,
.autocomplete-container .e-autocomplete:not(:placeholder-shown) + .floating-label {
  top: -10px;
  left: 10px;
  font-size: 12px;
  color: #000;
}

.e-autocomplete.e-control-wrapper {
  width: 100%;
}

.box-container {
  position: relative;
  display: inline-block;  /* Adjust width as needed */
}

.box-label {
  position: absolute;
  top: -10px;  /* Adjust this value to place label exactly on the border */
  left: 10px;
  background-color: white;  /* Match this to the background of your component */
  padding: 0 5px;
  font-size: 14px;
  color: black;
  text-transform: none;
}
