.main-schedule.e-schedule .e-vertical-view .e-header-cells, .main-schedule.e-schedule .e-timeline-month-view .e-header-cells {
  padding: 0;
  text-align: center !important;
}

.main-schedule.e-schedule .e-month-view .e-work-cells .count-text {
  color: green;
  font-size: 14px;
  float: left;
  margin: -22px 0px 0 60px;
}
.main-schedule.e-schedule .e-month-view .e-work-cells .e-date-header{
  color: black;
  font-size: 16px;
  font-weight: bold;
}
.main-schedule.e-schedule .e-header-cells .weekly-date {
  font-size: 16 px;
  color: blue;
}
.main-schedule.e-schedule .e-header-cells .count-text {
  color: green;
  font-size: 14px;
}
.main-schedule.e-schedule .date-text {
  font-size: 14px;
  color: purple;
}

.main-schedule.e-schedule.e-device .date-text {
  font-size: 12px;
}

/* .main-schedule.e-schedule .count-text {
  font-size: 11px;
} */

/* .main-schedule.e-schedule .e-month-view .count-text{
  margin: -20px 2px 0 0;
  color: green;
}  */

/* .main-schedule.e-schedule.e-rtl .e-month-view .count-text {
  float: left;
  color: green;
}  */