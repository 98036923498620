@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin.scss";

.ag-theme-alpine {
  @include ag-theme-alpine((
    // use theme parameters where possible
    alpine-active-color: #153359,
          foreground-color: #3F4254,
          odd-row-background-color: #fff,
          control-panel-background-color: #fff,
          range-selection-border-color: #E9EDF7

));
  html,
  body {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }

  .ag-header {
    // or write CSS selectors to make customisations beyond what the parameters support

    background-color: white;
  }
  .ag-horizontal-left-spacer{
    visibility: hidden;
  }

  .ag-center-cols-viewport {

  }
  .ag-theme-alpine {
    font-family: "DM Sans", sans-serif;
    letter-spacing: 1.2px;
  }
  .ag-root-wrapper {
    border: none;
  }
  .ag-header-cell {
    color: #000000;
  }
  .ag-cell {
    color: #000000;
    text-transform: uppercase;
  }
  .ag-standard-button {
    color: #1B2559;
  }
  .redFont {
    color: red
  }
  .blueFont {
    color: blue;
  }
  .bold {
    font-weight: 600;
  }
  .ag-row-level-1.ag-row.ag-after-created {
    min-height: 300px;
    height: auto !important;
  }
  .ag-theme-alpine .ag-row-selected {
    background-color: #dceaf1c2;
  }
}
