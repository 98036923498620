.box-container {
  position: relative;
  display: inline-block;  /* Adjust width as needed */
  width:100%;
}

.box-label {
  position: absolute;
  top: -10px;  /* Adjust this value to place label exactly on the border */
  left: 10px;
  background-color: white;  /* Match this to the background of your component */
  padding: 0 5px;
  font-size: 14px;
  color: black;
  text-transform: none;
}
